import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-[#F4F4F4] font-sans ">
      <div className="container px-6 py-12 mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="font-sans p-8 text-[#33363B] text-center md:text-lg md:p-4">
            DEV HACKATHON 2024
          </p>
          <p className="font-sans p-8 text-[#33363B] text-center md:text-lg md:p-4">
            © 2024 SYS&COTECH
          </p>
          <div className="flex space-x-4 md:space-x-8">
            <a
              href="https://www.facebook.com/SysAndCoTech"
              className="text-[#33363B]"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.instagram.com/syscotechclub_/"
              className="text-[#33363B]"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.youtube.com/@syscotechclub6784"
              className="text-[#33363B]"
            >
              <FaYoutube size={24} />
            </a>
            <a
              href="https://www.facebook.com/SysAndCoTech"
              className="text-[#33363B]"
            >
              <FaTwitter size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
