import React from "react";
import Databank from "../assets/databank.png";
import fibo from "../assets/Fibo.png";
import nasha from "../assets/Nasha.png";
import rine from "../assets/Rine.png";

export default function Sponser() {
  return (
    <div className="bg-[#5681EA] py-14 sm:py-22">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-5xl font-light tracking-tight leading-8 text-[#f4f4f4] sm:text-3xl md:text-4xl lg:text-5xl">
          Ерөнхий ивээн тэтгэгч
        </h2>
        <div className="mx-auto mt-10 ">
          <img
            className="col-span-2 max-h-32 w-full object-contain lg:col-span-1"
            src={Databank}
            alt="Transistor"
            width={158}
            height={48}
          />
        </div>
      </div>
      <br />
      <br />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-light tracking-tight leading-8 text-[#f4f4f4] sm:text-3xl md:text-4xl lg:text-5xl">
          Ивээн тэтгэгч
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={fibo}
            alt="Transistor"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={nasha}
            alt="Reform"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={rine}
            alt="Tuple"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
}
