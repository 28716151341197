import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "tailwindcss/tailwind.css";
import day1 from "./assets/HackDay1.png";
import day2 from "./assets/HackDay2.png";

import { Zoom, Navigation, Pagination } from "swiper/modules";

export default function App() {
  return (
    <div className="h-screen bg-[#5681EA] w-full font-sans py-10">
      <div className="flex justify-center items-center"></div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        zoom={true}
        navigation={true}
        modules={[Zoom, Navigation, Pagination]}
        className="h-full xl:w-6/12 md:w-6/12 lg:w-5/12 py-10 xl:w-3/12"
      >
        <SwiperSlide>
          <h1 className="text-center text-4xl font-main text-[#f4f4f4]">
            DAY 1
          </h1>
          <div className="swiper-zoom-container">
            <img src={day1} alt="Nature 1" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-center text-4xl font-main text-[#f4f4f4]">
            DAY 2
          </h1>
          <div className="swiper-zoom-container">
            <img src={day2} alt="Nature 2" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
