import Mentor from "./components/Mentor/Mentor";
import Sponser from "./components/Sponser/Sponser";
import Status from "./components/Status/Status";
import Swiper from "./components/Swiper/Swiper";
import Footer from "./components/Footer/Footer";
import Scroll from "./components/InfitiyScroll/InfinityScroll";
import Hero from "./Pages/Hero";

function App() {
  return (
    <div className="App">
      <Hero />
      <Mentor />
      <Sponser />
      <Status />
      <Swiper />
      <Footer />
    </div>
  );
}

export default App;
