const stats = [
  { id: 1, name: "Шагналын сан", value: "3,000,000" },
  { id: 2, name: "Хакатонд оролцогч", value: "80" },
  { id: 3, name: "Оролцогч баг", value: "18" },
  { id: 4, name: "Шинэ санаа", value: "18" },
];

export default function Status() {
  return (
    <div className="bg-[#4f4f4] py-6 sm:py-12">
      <div className="text-5xl text-center font-light tracking-tight text-[#333b36]"></div>
      <div className="mx-auto max-w-7xl py-4 px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-[#333b36]">
                {stat.name}
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-[#333b36] sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
