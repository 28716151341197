import React, { useState, useEffect } from "react";
import HTimer from "./HTimer.module.css";

const HeaderWithTimer = ({ title, startDate, endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="header-timer-container text-center">
        <h1 className="header-title text-[#F4F4F4] font-main text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
          {title}
        </h1>
        <div className="timer text-[#F4F4F4] font-main text-xl md:text-2xl lg:text-3xl xl:text-4xl ">
          {timerComponents.length ? (
            timerComponents
          ) : (
            <span className="font-main">Countdown complete!</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderWithTimer;
