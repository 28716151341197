import och from "../assets/och.jpg";
import erhem from "../assets/Erkhembayar.jpg";
import rinchin from "../assets/dugaraa.jpg";
import data from "../assets/mendsaihan.jpg";
import namuun from "../assets/badarchin.jpg";
import enhjin from "../assets/Enhjin.jpg";
import suuri from "../assets/suuri.jpg";
const people = [
  {
    name: "А.Оч",
    role: "Timely.mn Founder, CTO",
    imageUrl: och,
  },
  {
    name: "Г.Эрхэмбаяр",
    role: "TomYo ЭдТек Co-Founder, R&D CEO",
    imageUrl: erhem,
  },
  {
    name: "О. Ринчиндугар",
    role: "TomYo Edtech LLC , CTO",
    imageUrl: rinchin,
  },
  {
    name: "Г.Мэндсайхан",
    role: "Databank LLC , Cheif Software Engineer",
    imageUrl: data,
  },
  {
    name: "Т. Намуун",
    role: " Badarchin LLC Founder & Co-Founder ",
    imageUrl: namuun,
  },
  {
    name: "Б.Энхжин",
    role: "Creative Content Lead, CCO, Гэр өргөө ТББ, GerHub NGO",
    imageUrl: enhjin,
  },
  {
    name: "С.Суурьбаатар",
    role: "Nasha Tech LLC, Engineering Team Manager, Senior engineer",
    imageUrl: suuri,
  },
];

export default function Mentor() {
  return (
    <div className="bg-white py-12 sm:py-22">
      <div className="text-center text-5xl font-light tracking-tight text-[#33363b]">
        Менторууд
      </div>
      <div className="mx-auto grid max-w-7xl gap-y-20 p-10 lg:px-8 xl:grid-cols-2">
        <ul
          role="list"
          className="grid gap-x-8 gap-y-12 sm:grid-cols-3 sm:gap-y-16 xl:col-span-2"
        >
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center flex-col gap-x-7">
                <img
                  className="h-44 w-44 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
                <div>
                  <br />
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-[#33363b] text-center">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-[#5681EA] text-center">
                    {person.role}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
